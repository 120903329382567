<script>
import { nanoid } from 'nanoid'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import AppHeaderLogo from '/~/components/app/header/app-header-logo.vue'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useAuth } from '/~/composables/auth'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'

export default {
  components: {
    BaseAction,
    BaseIcon,
    AppHeaderLogo,
  },
  setup() {
    const router = useRouter()
    const { logout } = useAuth()
    const { isDarkThemeForEwallet } = useCms()
    const { isLogoutEnabled } = useProvider()
    const {
      leftMenu,
      settingsMenu: cmsSettingsMenu,
      uiReady,
      lockKeyboardAccessArea,
      unlockKeyboardAccessArea,
    } = useUI()

    const keyboardAccessAreaId = 'mobile-menu'

    const closeButtonElement = ref(null)
    const isToggled = ref(false)
    const rootElement = ref(null)
    const menuItemElement = ref(null)

    const settingsMenu = computed(() => {
      return cmsSettingsMenu.value.length > 0
        ? cmsSettingsMenu.value
        : [
            {
              id: nanoid(),
              enabled: true,
              label: 'Logout',
              action: {
                type: 'action',
                value: 'logout',
              },
              'mobile-action': {
                type: 'action',
                value: 'logout',
              },
              icon: 'v2/heroic/logout',
              hideCarrot: true,
            },
          ]
    })

    function onCloseClicked() {
      isToggled.value = false
    }

    function onAppMenuButtonClicked() {
      isToggled.value = true
    }

    function onActionClicked(action) {
      isToggled.value = false

      setTimeout(() => {
        action.onClick()
      }, 500)
    }

    function onLeftMenuClicked(action) {
      if (action.type === 'action') {
        return
      }

      isToggled.value = false
    }

    watch(isToggled, (isToggled) => {
      if (isToggled) {
        const menuItems = leftMenu.value.concat(settingsMenu.value)
        let focusElement = closeButtonElement.value

        menuItems.some((item, index) => {
          if (
            item.action?.type === 'page' &&
            router.currentRoute.matched.some(
              ({ name }) => name === item.action.value
            )
          ) {
            focusElement = menuItemElement.value[index]?.$el
            return true
          }
        })

        lockKeyboardAccessArea({
          id: keyboardAccessAreaId,
          rootElement: rootElement.value,
          focusElement: focusElement,
          delay: 300,
        })
      } else {
        unlockKeyboardAccessArea(keyboardAccessAreaId)
      }
    })

    router.afterEach(() => {
      isToggled.value = false
    })

    return {
      logout,
      isDarkThemeForEwallet,
      isLogoutEnabled,
      leftMenu,
      settingsMenu,
      uiReady,
      closeButtonElement,
      isToggled,
      rootElement,
      menuItemElement,
      onCloseClicked,
      onAppMenuButtonClicked,
      onActionClicked,
      onLeftMenuClicked,
      ui,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) {
        return
      }

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    activeId() {
      return this.activeTopMenuItem?.id ?? null
    },
  },
}
</script>

<template>
  <div
    v-if="ui.mobile"
    ref="rootElement"
    class="fixed h-full w-full overflow-auto transition-all"
    :class="{
      'left-0 top-0': isToggled,
      'bg-gray-900 text-white': isDarkThemeForEwallet,
      'bg-white text-eonx-neutral-800': !isDarkThemeForEwallet,
      'invisible opacity-0 delay-500': !isToggled,
    }"
    @keydown.esc="close()"
  >
    <div
      class="w-full max-w-[364px] transform px-6 py-[35px] transition-all duration-300"
      :class="{
        '-translate-x-full': !isToggled,
        'delay-200': isToggled,
      }"
    >
      <div class="mb-10 flex items-center justify-between">
        <app-header-logo
          v-if="ui.mobile"
          :max-width="92"
          @click="onLeftMenuClicked"
          @keyup.enter="onLeftMenuClicked"
          @keyup.space="onLeftMenuClicked"
        />
        <button ref="closeButtonElement" @click="onCloseClicked">
          <base-icon svg="plain/close" :size="30" :aria-hidden="false" />
        </button>
      </div>
      <template v-if="leftMenu.length > 0">
        <div class="flex flex-wrap justify-center gap-x-[35px] gap-y-6">
          <template v-for="{ id, action, icon, label } in leftMenu">
            <base-action
              v-if="uiReady && action && action.enabled"
              :key="id"
              ref="menuItemElement"
              v-bind="action"
              :label="label"
              color=""
              class="w-20"
              @click="onLeftMenuClicked(action)"
            >
              <span
                class="flex h-20 items-center justify-center rounded-lg"
                :class="{
                  'bg-primary text-white': activeId === id,
                }"
              >
                <base-icon :svg="icon" :size="48" />
              </span>
              <span
                class="mt-[5px] flex w-full items-center justify-center text-center text-sm font-semibold"
              >
                {{ label }}
              </span>
            </base-action>
            <div
              v-else
              :key="id + 'icon'"
              class="flex h-20 w-20 items-center justify-center text-eonx-neutral-600"
            >
              <base-icon :svg="icon" :size="48" />
            </div>
          </template>
        </div>
        <div class="mb-4 mt-16 border-b-2" />
      </template>
      <div class="space-y-4">
        <template v-if="uiReady && settingsMenu.length > 0">
          <div class="flex flex-wrap justify-center gap-x-[35px] gap-y-6">
            <template
              v-for="{
                id,
                icon,
                label,
                'mobile-action': mobileAction,
                divider,
                hideCarrot,
              } in settingsMenu"
            >
              <div
                v-if="divider"
                :key="id + 'divider'"
                class="w-full border-b-2"
              />
              <base-action
                v-else
                :key="id"
                ref="menuItemElement"
                v-bind="mobileAction"
                :label="label"
                color=""
                class="flex w-full items-center font-bold"
                :class="{
                  'text-white': !isDarkThemeForEwallet,
                }"
                @click="onLeftMenuClicked(mobileAction)"
              >
                <template #entry>
                  <span
                    class="mr-4 flex h-12 w-12 items-center justify-center rounded-full bg-gray-700"
                  >
                    <base-icon :svg="icon" :size="24" />
                  </span>
                  <span
                    :class="{
                      'text-eonx-neutral-800': !isDarkThemeForEwallet,
                    }"
                  >
                    {{ label }}
                  </span>
                  <base-icon
                    v-if="!hideCarrot"
                    svg="v2/heroic/chevron-right"
                    :size="24"
                    class="ml-auto"
                    :class="{
                      'text-eonx-neutral-800': !isDarkThemeForEwallet,
                    }"
                  />
                </template>
              </base-action>
            </template>
          </div>
        </template>
      </div>
    </div>
    <portal v-if="ui.mobile && uiReady" to="app-header-menu">
      <button
        class="mr-5"
        aria-label="Open menu"
        @click="onAppMenuButtonClicked"
      >
        <base-icon svg="v2/box-icons/bx-menu-alt-left-v2" :size="28" />
      </button>
    </portal>
  </div>
</template>
